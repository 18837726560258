import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Helmet } from "react-helmet"

import background from "../images/mqb-hero.png"
import Layout from "../components/Layout"
import "../components/Layout.css"
import Seo from "../components/Seo"

//styles
const Feature = styled.div`
  a {
    display: inline-block;
  }

  h1 {
    color: rgba(81, 58, 233, 1);
    transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);

    @media (max-width:640px) {
      line-height: 1.1
    }
  }
`

const FeatureBoxWrapper = styled.div`
  overflow: hidden;
`

const FeatureBox = styled.div`
  background: url(${background});
  background-position: center;
  background-size: cover;
  height: 50vh;
  margin-top: 12px;
  border-radius: 18px;
  animation: BackgroundZoomOut 1s ease;
  transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);
  overflow: hidden;
  ${'' /* transition: transform 1s; */}

  :hover {
    ${'' /* transform: scale(1.05); */}
    animation: BackgroundZoomIn 1s ease;
    overflow: hidden;

  }
`
const BackArrow = styled.div`
  width: 57px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;

  :hover i, :hover p {
    color: rgba(60, 200, 215, 1);
  }  

  i {
    content: "\f007";
    font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
    color: rgba(0, 0, 0, .78);
    font-style: normal;
  }

  p {
    font-family: 'Alpino-Bold', 'Arial', 'Helvetica', 'sans-serif';
    font-size: 19.2px;
    margin: 0;
  }

  @keyframes BackgroundZoomIn {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes BackgroundZoomOut {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`

const Introduction = styled.div`
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5vw;

  a {
    font-weight: 500;
    color: rgba(81, 58, 233, 1);
  }

  a:hover {
    color: rgba(60, 200, 215, 1);
  }

  @media (max-width:640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const ProjectOverViewWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectOverview = styled.div`
`
const ProjectDetailsWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectDetails = styled.div`
  hr {
    opacity: 0;

    @media (max-width:640px) {
      opacity: 1;
      padding-top: 24px;
    }
  }

  @media (max-width:640px) {
    margin-top: 24px;
  }
`

const ProjectDescription = styled.div`
  p {
    margin-right: 15vw;

    @media (max-width:640px) {
      margin-right: auto;
    }
  }

  ul {
    list-style: none;
  }

  ul li::before {
    content: "✅";
    display: inline-block;
    margin-right: 0.2rem;
    list-style-position: outside;
  }

  ol p::before {
    content: "👍🏼";
    display: inline-block;
    margin-right: 0.2rem;
    margin-left: 2rem;
    list-style-position: outside;
    width: auto;
    height: auto;
    background: none;
  }

  ol p {
    font-family: Alpino-Medium, Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 21px;
    margin-top: 0;
  }

  ol {
    list-style: none;
    counter-reset: steps;
  }

  ol li {
    counter-increment: steps;
  }

  ol li::before {
    content: counter(steps);
    margin-right: 0.5rem;
    background: rgba(0, 0, 0, .78);
    color: white;
    width: 1.4em;
    height: 1.2em;
    border-radius: 60%;
    display: inline-grid;
    place-items: center;
    line-height: 1.2em;
    padding-bottom: 3px;
  }

  li {
    font-family: Alpino-Medium, Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 21px;
  }

  img {
    width: 100%;

    @media (max-width:640px) {
      width: 100%;
    }
  }
`

const ImageDuo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.5vw;

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }

  img {
    width: 100%;
  }
`

const ScrollOnUp = styled.div`
    display: block;

    :hover i, :hover p {
        color: rgba(60, 200, 215, 1);
    }
`

const ProjectWrapper = styled.div`
  max-width: 1344px;
  margin: 0 auto;
`

const mqb = () => (
    <Layout>
      <Seo title="Data Analytics" />
        <Helmet>
            <script src="https://kit.fontawesome.com/077761ae24.js" crossOrigin="anonymous"></script>
        </Helmet>
        <div className="Hero">
        <div id="top"/>
        <ProjectWrapper>
            <Feature>
            <Link to="/work">
                <BackArrow>
                <i className="fas fa-long-arrow-alt-left"></i>
                <p>back</p>
                </BackArrow>
            </Link>
            <h1>merQbiz® Data Analytics</h1>
            <FeatureBoxWrapper>
                <FeatureBox />
            </FeatureBoxWrapper>
            </Feature>
            <Introduction>
            <ProjectOverViewWrapper>
                <ProjectOverview>
                <h2>Project Overview</h2>
                <h4>merQbiz was a venture with <a href="https://voith.com/corp-en/index.html" target="_blank" rel="noreferrer">VOITH</a> and <a href="https://www.bcgdv.com/" target="_blank" rel="noreferrer">BCGDV</a>, creating the first ever commodity-level eCommerce marketplace and logistics platform in the recovered paper industry. With the addition of sensory hardware, we were able to design and build one of the first quality analytics product as well.</h4>
                <p>Qualitative tools are valuable as they increase visibility and planning capabilities to paper mills of what are otherwise macro-grades of recycled paper. The concept served as a mini-disruptor, relying on the combination of experienced recovered paper brokers plus large sets of data to help quantify the condition and consistency of recycled raw goods. As with all new ventures, we started from scratch, and I was honored to serve as the loan Sr. Product Designer on the project.</p>
                <h3>Denouement</h3>
                <p>Since the conclusion of this project, VOITH made the difficult decision to close merQbiz's doors largely due to lack of traction during the pandemic. The technology was dissolved, however several product design processes and insights remain.1</p>
                </ProjectOverview>
            </ProjectOverViewWrapper>
            <ProjectDetailsWrapper>
                <ProjectDetails className="projectDetails">
                <hr></hr>
                <h5 className="details">Credits</h5>
                <p className="details">Vision from <a href="https://www.linkedin.com/in/chrisbrandsey/" target="_blank" rel="noreferrer">Chris Brandsey</a>, Managed by <a href="https://www.linkedin.com/in/lintam/" target="_blank" rel="noreferrer">Lin Tam</a> and <a href="https://www.linkedin.com/in/maverick-arcena-4a6858ba/" target="_blank" rel="noreferrer">Maverick Arcena</a>, Data team of <a href="https://www.linkedin.com/in/benjaminperson/" target="_blank" rel="noreferrer">Ben Person</a> and <a href="https://www.linkedin.com/in/nick-brodsky/" target="_blank" rel="noreferrer">Nick Brodsky</a>, designed by yours truly and built by <a href="https://www.linkedin.com/in/nguyenphuc17/" target="_blank" rel="noreferrer">Fred Nguyen</a>, <a href="https://www.linkedin.com/in/reikurata/" target="_blank" rel="noreferrer">Rei Kurata</a>, <a href="https://www.linkedin.com/in/kirk-chu-0abb3234/" target="_blank" rel="noreferrer">Kirk Chu</a>, <a href="https://www.linkedin.com/in/niklas-wenzler-b24a22148/" target="_blank" rel="noreferrer">Nick Wenzler</a> & <a href="https://www.linkedin.com/in/scott-alexander-b5bb9b/" target="_blank" rel="noreferrer">Scott Alexander</a>. This was an amazingly small and agile team.</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Client</h5>
                <p className="details">merQbiz®</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">agency</h5>
                <p className="details">In-house start-up</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Deliverable</h5>
                <p className="details">Data Lake, Marketing Website, Mobile App, Progressive Web App, and Data Analytics Suite.</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Goal</h5>
                <p className="details">Grow the business by introducing a suite of products based on qualitative insights as a service.</p>
                </ ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">RESULTS</h5>
                <p className="details">A full suite of qualitative products was designed and developed that would scale claim capabilities, provide quality and procurement insights, and lessen friction of communication between many parties involved in commodity trading.</p>
                </ProjectDetails>
            </ProjectDetailsWrapper>
            </Introduction>
            <ProjectDescription className="projectChapter">
            <h2>Discovery</h2>
            <h3>Background</h3>
            <p>I was hired by merQbiz to help pivot their eCommerce platform which was designed for a seller's market into one designed for a buyer's market. Sanctions against the U.S. selling recovered paper to China, and subsequently other Southeast Asian countries flipped markets in a short amount of time. Exposing a product to such a quick change exposed some discoveries. An important one was that key users were often not categorically buyers nor sellers, and subsequent design work was influenced by this revelation.</p>
            <h3>Finding the Unknown</h3>
            <p>Through conversations with subject matter experts and customers, our team identified early on there is a large visibility block of material before it enters a paper mill. Current quality auditing practices involved costly and dangerous bale breaking which involved dissecting a bale and measuring by weight contaminants such as plastic, moisture, and non-fiber contents.</p>
            <h3>Enter the Probe</h3>
            <p>Third party R&D had discovered a technique using near-infrared technology that could detect major contaminants to within 98% accuracy. With this device, a smaller team could drill and probe bales via a scalable and more sustainable method. Using light refraction and multiple sample points within a bale, a percentage break down could be generated and more importantly could be ingested for analysis. Moisture, plastic, and ash contaminants could be detected as well as the length of fiber.</p>
            <StaticImage
                src="../images/mqb-device.png"
                alt="Close-up of the end of a metal probe showing an optical sensor window"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="fullWidth"
            />
            <div className="imgcaption">The "optic" end of a giant bass-guitar size bale probe. That lens shown could pick up variances within a bale that would translate to moisture, plastic, and non-fiber readings. </div>
            <h3>Imagining a Data Product</h3>
            <p>Based on the concept of "Internet of Things", our team did exploration on how to import data into a large data lake, uniquely tag readings and surface them in a series of dashboards that would give insights into the quality of incoming recovered paper. Those dashboards and the algorithms are secret sauce really, but I share some design thoughts in <Link to="/bv/">this project</Link> on the design system that united it all.</p> 
            <p>In early days, I led some initial workshops to help amplify value and align visions across stakeholders. We had interviews with paper mill engineers, mill operations teams, and indirectly other R&D teams at paper mills on how information could be organized.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/mqb-stickies.png"
                    alt="Various sticky notes on a grid on the wall"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
                <StaticImage
                    src="../images/mqb-whiteboard.png"
                    alt="Whiteboard storyboard cell drawings with wireframes notes below"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
            </ImageDuo>
            <div className="imgcaption">Early landscape mapping sessions with stakeholders, and conceptual storyboarding of how a new data product could be useful and used.</div>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Observations & Hypothesis</h2>
            <h3>Persona Development & Journey Mapping</h3>
            <p>New personas were developed from ongoing interviews, which allowed us to both expand product audience and determine stages of interaction, pain points, and potential values. This was a major shift from the singular procurement persona stream of the eCommerce platform. We expanded to mill operations, include opportunities dockside, as well as the accounting and management side of paper production. We were lucky that we had our own inspection team, who provided a great amount of operational information.</p> 
            <p>Competitive research was done on other bale-documentation/data-input software and comparative research was continually done studying usability within similar data input software and successful administrative toolsets to support the ingestion of probe data.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/mqb-personas.png"
                    alt="Snapshot of a document showing three persona profiles, each with pain points and product values"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
                <StaticImage
                    src="../images/mqb-cust-journey.png"
                    alt="Whiteboard sketches from Maverick Arcena of a journey mapping of the probe"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
            </ImageDuo>
            <div className="imgcaption">Example of personas refined for a feature surrounding claims & a whiteboard session journey mapping with our talented product manager Maverick.</div>
            <h3>Wireframing & Lo-Fidelity Screen Flows</h3>
            <p>For each feature that was designed, the process for our team was similar. Following a hand-off of product requirements, I would expedite vision by creating a collaborative atmosphere and designing completely in lo-fidelity. Sometimes this was a mix of rough wireframes created with a tool like Balsamiq. Other times it was rough static designs created with grayscale design library components, created in Figma. Many many times, it was conversations and sketching at the whiteboard.</p>
            <p>In office, initial static screens would be printed and hung in our "war room" where we held stand-up. Like a game of pick-up, anyone could grab any of us in product and have us run through current designs and thought process. Marketing could scribble content edits and offer branding support. Sales could preview any direction we were heading. Management could poke holes in designs, and pinpoint pleasure points where business aligned. I wouldn't say pin-ups are the most efficient to produce and maintain, but the energy and feedback was palpable. The trust built was communal and very open.</p>
            <StaticImage
                src="../images/mqb-wireframe.png"
                alt="Image of a bunch of screens printed out and hung on a whiteboard wall with painter's tape. There are whiteboard marker notes surrounding the paper print-outs."
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="fullWidth"
            />
            <div className="imgcaption">Sample mix of wireframes and quasi-high fidelity designs in the "war room" aka "ping-pong room" for a location-based probe input product. I took over the whole room, love whiteboard paint.</div>
            <p>As designs and direction became more resolute, user goals and usability were examined at every step as screens were updated. This was a play space for paper prototypes, and light demos with Q&A. During safer-at-home time, the process was moved online to Figma where entire projects were laid out in a similar fashion. Cross-functional feedback would be documented with commenting, and I often used comments as a design ticket list.</p>
            <StaticImage
                src="../images/mqb-static.png"
                alt="Figma screens laid out in a similar matter as the wireframes above, in different screen flows by persona"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="fullWidth"
            />
            <div className="imgcaption">Sample spread of an admin feature laid out in Figma in a similar manner to the "war room" with user flows laid out horizontally per persona and company role.</div>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Iterations</h2>
            <h3>Rebuild with Ownership</h3>
            <p>Early versions of our adminstrative app were based on integration within a 3rd party platform. As is common, this sped up launch, but left us with expensive overhead and limitations on innovation. One major decision, and credit to the management team, was that we began to design and build an admin app from the bottom up. Here's a sample list of features that were rolled out to allow customers to have secure authentication and self-administrate.</p>
            <ul>
                <li>Login with Multi-factor Authentication</li>
                <li>Account Creation and Management</li>
                <li>Forgot Password</li>
                <li>Inspection Data Auditing</li>
                <li>Portal for Unpaid Users</li>
                <li>Migration of Dashboards to this Admin app</li>
                <li>Roles and Permissions</li>
                <li>Dashboard Scaling</li>
                <li>Training</li>
                <li>Company Settings</li>
                <li>Automated Data Integration</li>
                <li>Device Management</li>
                <li>Marketing Website for the Public</li>
            </ul>
            <p>After wireframes and design concepts were hashed out, I entered a four-stage process for feedback, approval and build. Each design team and company will have their own process, but mine followed the following for each feature set.</p>
            <ol>
                <li>Design Approval 1</li>
                <p>Major flows / Key screens / Approval by target stakeholders</p>
                <li>Engineering Validation</li>
                <p>Technical diagrams / UI components / Feedback by engineering</p>
                <li>Design Approval 2</li>
                <p>User testing / High-fidelity designs / Approval by product</p>
                <li>Executive Approval</li>
                <p>Fully baked prototype / Dev pass-off / Approval by management team</p>
            </ol>
            <h3>Rapid Prototyping</h3>
            <p>For each feature-set, I developed rapid prototypes in Figma, using symbols, overlays and variants. These went through a rigorous process of review sessions with stakeholders, demonstrations to engineering and sales, and user testing as needed. Most testing involved usability analysis, of which I conducted in person and remote feedback sessions.</p>
            <StaticImage
                src="../images/mqb-prototype.png"
                alt="Figma screenshot showing many transitional state paths in a feature prototype"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="blurred"
                layout="fullWidth"
            />
            <div className="imgcaption">Sample connections for an order input tool prototype. Rapid development of these prototypes helped the project process and served as an estimation asset of effort with our dev team.</div>
            <p>Finished prototypes would be fed into development tickets, which were pulled into sprints. I would typically polish visual designs right up to sprint planning, finalizing UI styling and systematizing components with feedback from our Front-end team. We began by customizing existing Design Systems such as Shopify's <a href="https://polaris.shopify.com/" target="_blank" rel="noreferrer">Polaris</a>, but soon developed our own BaleVision™ design style and component library. Read more about that <Link to="/bv/">here</Link>.</p>
            <ImageDuo>
                <StaticImage
                    src="../images/mqb-hifi.png"
                    alt="Higher definition screen design showing annotations for Product and Engineering communication"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
                <StaticImage
                    src="../images/mqb-hifi-2.png"
                    alt="Sample hi-fi homepage screen showing thumbnail tile navigation"
                    formats={["AUTO", "WEBP", "AVIF"]}
                    placeholder="blurred"
                    layout="fullWidth"
                />
            </ImageDuo>
            <div className="imgcaption">Examples of high-fidelity designs using the BaleVision™ Design System. We switched from Sketch to Figma half-way through the initial design process. Figma proved a winner for dev handoff.</div>
            </ProjectDescription>
            <ProjectDescription className="projectChapter">
            <h2>Terminus</h2>
            <p>I was most proud that during my tenure we started desk-checking visual designs early on. This involved sitting with an engineer and working out visual designs and component function in local or sandbox environments. I was able to learn and give direct feedback, something by which I will forever feel rewarded. I imagine it saved us loads of headaches, and was a process that ceased when I began.</p>
            <p>In a waterfall design process, I also really appreciated the discipline of designing everything early on out in the open. Heavy lifting gets done in closeknit quarters with the product team, and my job is to advocate for the user, advocate for better design, and then work closely with engineer on the build.</p>
            <h3>Conclusions</h3>
            <p>Before our shop closed up, we had released an interesting feature of allowing customers to visualize and track claims against large export purchases as well as the beginning processes of certifying shipped material. We had work in the pipeline to scale the migration of data, increase collaboration with messaging, and expand business opportunites to brokers.</p>
            <p>I learned an awful lot about capabilities, both of a product and dev team, but also how each capability we added gave us the timbre of business possibilities. There were true innovation plays down the road, and my regret is that we didn't test those waters.</p>
            </ProjectDescription>
            <ScrollOnUp 
            onClick={() => scrollTo('#top')}>
            <div className="ScrollToTop">
                <p>Scroll to top</p>
                <i className="fas fa-angle-up"></i>
            </div>
            </ScrollOnUp>
        </ProjectWrapper>
        </div>
    </Layout>
  )

export default mqb